import React from "react";
import { SideBar } from "./sidebar";
import { getUsersByRoles, getWalletUsers } from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal, Spinner } from "react-bootstrap";
import { EditUser } from "./helper/edit_user";
import Select from "react-select";
import {
  createWallet,
  deleteWallet,
  getAllWallets,
  getWallet,
  updateWallet,
} from "../../models/wallet";
import { getLoggedUser } from "../../state/user";
import { apiUrl } from "../../constants/global";
import { HomeBanner } from "../home/homeBanner";
import { getAllOrganization } from "../../models/organization";
import { getAllGroups } from "../../models/groups";

const user = getLoggedUser();
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9 }),
};

const selectStylesPop = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: "justify",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
};

const wallet_types = {
  admin: "Added by admin",
  sitevisit: "Site Visit",
  enroll: "Enroll",
  wallet_recharge: "Recharge",
  withdraw: i18n.t("withdraw"),
};
const wallet_statuses = {
  A: i18n.t("approved"),
  P: "Waiting for admin approval",
  F: i18n.t("waiting_for_finance_approval"),
  W: "Withdraw requested",
  WA: i18n.t("withdraw_request_approved_by_admin"),
  R: "Refunded",
};

export class UsersWallet extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      update_popup: false,
      add: {},
      data: {},
      errors: {},
      is_loading: false,
      transactions: [],
      customers: [],
      all_customers: [],
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
      },
      clients: [],
      groups: [],
      credit_sum: 0,
      debit_sum: 0,
      balance_sum: 0,
      hold_sum: 0,
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "70px",
        },
        {
          name: i18n.t("mzadcom_id"),
          selector: "user",
          sortable: true,
          wrap: false,
        },
        {
          name: i18n.t("username"),
          selector: "user",
          sortable: true,
          wrap: true,
          cell: (row) =>
            `${row.user && row.user_info ? row.user_info.name : ""}`,
        },
        {
          name: i18n.t("transaction"),
          selector: "transaction_id",
          sortable: true,
        },
        {
          name: i18n.t("deposit"),
          selector: "credit",
        },
        {
          name: i18n.t("withdraw"),
          selector: "debit",
        },
        {
          name: i18n.t("balance"),
          selector: "balance",
        },
        {
          name: i18n.t("reference"),
          selector: "reference",
          wrap: true,
        },
        {
          name: i18n.t("type"),
          selector: "type",
          wrap: true,
          cell: (row) => `${row.type ? wallet_types[row.type] : ""}`,
        },
        {
          name: i18n.t("status"),
          selector: "status",
          wrap: true,
          cell: (row) => `${row.status ? wallet_statuses[row.status] : ""}`,
        },
        {
          name: i18n.t("admin_approved_on"),
          wrap: true,
          selector: "admin_approved_on",
        },
        {
          name: i18n.t("fm_approved_on"),
          wrap: true,
          selector: "fm_approved_on",
        },
        {
          name: i18n.t("date"),
          wrap: true,
          selector: "created_at",
        },
        {
          name: i18n.t("receipt"),
          selector: "file_receipt",
          cell: (row) => {
            if (row.file_receipt) {
              return (
                <a href={row.file_receipt} target="_new">
                  {i18n.t("receipt")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("attatchment"),
          selector: "file_fm",
          cell: (row) => {
            if (row.file_fm) {
              return (
                <a href={row.file_fm} target="_new">
                  {i18n.t("receipt")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("action"),
          wrap: true,
          cell: (row) => {
            if (user.role === "A" && row.status === "P") {
              return (
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    let sts = row.status === "P" ? "F" : "A";
                    this.updateWalletRow(sts, row);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }

            if (user.role === "F" && row.status === "F") {
              return (
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    let sts = row.status === "P" ? "F" : "A";
                    this.updateWalletRow(sts, row);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
    this.updateWalletRow = this.updateWalletRow.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
    this.loadCustomers();
    this.loadAllCustomers();
    this.loadAllOrganisation();
    this.loadAllGroups();
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadCustomers() {
    this.setState({ is_loading: true });
    const response = await getWalletUsers();
    let data = response.data;
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });

      this.setState({ is_loading: false });
      this.setState({ customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async loadAllCustomers() {
    this.setState({ is_loading: true });
    let q = `roles=U&is_role_wise=${true}`;
    const response = await getUsersByRoles(q);
    let data = response.data["U"];
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });
      this.setState({ is_loading: false });
      this.setState({ all_customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }
  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
  }

  async loadAllTransactions(q) {
    this.setState({ is_loading: true });
    const response = await getAllWallets(q);
    let transactions = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ transactions: transactions });
      this.setState({ totalRows: meta.total });
      this.setState({
        credit_sum: meta["credit_sum"],
        debit_sum: meta["debit_sum"],
        balance_sum: meta["balance_sum"],
        hold_sum: meta["hold_sum"],
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async viewRow(id) {
    const response = await getWallet(id);
    let data = response.data;
    this.setState({ data, update_popup: true });
  }

  async updateWalletRow(status, row) {
    let payload = { status: status, id: row.id, user: row.user };
    const response = await updateWallet(payload, row.id);
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllTransactions(qstring);
  };

  async deleteRow(id) {
    var ans = window.confirm("Do you want to continue.?");
    if (ans) {
      const response = await deleteWallet(id);
      if (response.success) {
        swal(i18n.t("success"), "User deleted", "success", {
          buttons: false,
          timer: 2000,
        })
          .then((response) => {
            window.location.reload();
          })
          .catch((response) => {
            swal("Failed", "Something went wrong", "error");
          });
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createWallet(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "Fund added successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              let { input } = this.state;
              let q = new URLSearchParams(input).toString();
              this.loadAllTransactions(q);
              this.setState({ open_popup: false });
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.add;
    let errors = {};
    let isValid = true;
    if (!input["user"]) {
      isValid = false;
      errors["user"] = "Please select a user.";
    }
    if (!input["credit"]) {
      isValid = false;
      errors["credit"] = "Please enter an amount.";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadAllTransactions(qstring);
    }
  }

  render() {
    let { transactions, customers, all_customers, input, clients, groups } =
      this.state;
    const queryString = Object.keys(input)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(input[key])}`
      )
      .join("&");
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div className="d-flex w-30 w-mob-100">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title">
                        {i18n.t("mzadcom")} - {i18n.t("wallet")}
                      </h3>
                    </div>

                    <div className="p-0 m-0 d-flex justify-content-between align-items-center">
                      {!this.state.is_loading && transactions.length > 0 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <a
                            href={
                              apiUrl + "user_wallet/export?q=" + queryString
                            }
                            target="_new"
                          >
                            <i className="fa fa-print fa-1x text-dark px-1 mx-1" />
                          </a>

                          <a
                            href={apiUrl + "user_wallet/excel?q=" + queryString}
                            target="_new"
                          >
                            <i className="fa fa-file-excel-o fa-1x text-dark px-1 mx-1" />
                          </a>
                        </div>
                      )}
                      {user.role === "A" && (
                        <button
                          className="btn btn-sm btn-outline-dark rounded-5 p-1 px-2"
                          onClick={(event) => {
                            this.setState({
                              open_popup: !this.state.open_popup,
                            });
                          }}
                        >
                          <i className="fa fa-plus-circle" />
                          &nbsp;
                          <span className="d-mob-none">
                            {i18n.t("add_fund")}
                          </span>
                        </button>
                      )}
                    </div>

                    <div className="d-flex w-33 w-mob-100 gap-1">
                      <div className="col-sm-6 col-md-6">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Client"
                          name="client"
                          value={this.state.input.client}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_client")}</option>
                          {clients.length > 0 &&
                            clients.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.organization_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Group"
                          name="group"
                          value={this.state.input.group}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_group")}</option>
                          {groups.length > 0 &&
                            groups.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.group_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div className="text-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control textbox rounded-2"
                            placeholder={i18n.t("from_date")}
                            name="fdate"
                            value={this.state.input.fdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control textbox rounded-2"
                            placeholder={i18n.t("to_date")}
                            name="tdate"
                            value={this.state.input.tdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <select
                            className="form-control textbox rounded-2"
                            name="pay_type"
                            value={this.state.input.pay_type}
                            onChange={this.handleChange}
                          >
                            <option value="">{i18n.t("pay_type")}</option>
                            <option value="online">{i18n.t("online")}</option>
                            <option value="offline">{i18n.t("offline")}</option>
                            <option value="wallet">{i18n.t("wallet")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <select
                            className="form-control textbox rounded-2"
                            name="trans_type"
                            value={this.state.input.trans_type}
                            onChange={this.handleChange}
                          >
                            <option value="">
                              {i18n.t("transaction") + " " + i18n.t("type")}
                            </option>
                            <option value="deposit">{i18n.t("deposit")}</option>
                            <option value="withdraw">
                              {i18n.t("withdraw")}
                            </option>
                            <option value="hold">{i18n.t("hold")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <Select
                            options={customers}
                            onChange={(e) => {
                              let { input } = this.state;
                              input.customer = e.value;
                              this.setState({ input });
                            }}
                            name="customer"
                            styles={selectStyles}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            className="form-control textbox rounded-2 p-0"
                          ></Select>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="d-flex">
                          {(this.state.input.customer ||
                            this.state.input.fdate ||
                            this.state.input.tdate) && (
                            <button
                              className="btn btn-danger rounded-0"
                              onClick={(e) => {
                                let { input } = this.state;
                                input.customer = "";
                                input.fdate = "";
                                input.tdate = "";
                                this.setState({ input });
                                this.handleSearch(e);
                              }}
                            >
                              <i className="fa fa-times" />
                            </button>
                          )}
                          <button
                            className="btn btn-outline-dark rounded-5"
                            onClick={this.handleSearch}
                          >
                            <i className="fa fa-search fa-1x" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {!this.state.is_loading && transactions.length > 0 && (
                      <div className="">
                        <div class="payment-info-section">
                          <div class="bg-lt-green payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("deposit")} : {this.state.credit_sum}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                          <div class="bg-lt-orange payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("client_due")} :{" "}
                              {this.state.client_due || 0} {i18n.t("omr")}
                            </span>
                          </div>
                          <div class="bg-lt-red payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("hold")} : {this.state.hold_sum || 0.0}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                          <div class="bg-lt-blue payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("withdraw")} : {this.state.debit_sum}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                          <div class="bg-lt-green payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("balance")} : {this.state.balance_sum}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                        </div>
                        <div className="table-responsive ">
                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              fixedHeader
                              columns={this.state.columns}
                              data={transactions}
                              customStyles={customStyles}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={this.state.totalRows}
                              paginationPerPage={15}
                              paginationDefaultPage={this.state.currentPage}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: i18n.t(
                                  "transactions_per_page"
                                ),
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              onChangePage={this.handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size="md"
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("add_fund")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="single-page">
                <div className="col-md-12 d-block mx-auto">
                  <div className="wrapper">
                    <form
                      id="Register"
                      className="card-body"
                      tabindex="500"
                      autoComplete="off"
                    >
                      <div className="user">
                        <label>{i18n.t("user")}</label>
                        <Select
                          options={all_customers}
                          onChange={(e) => {
                            let { add } = this.state;
                            add.user = e.value;
                            this.setState({ add });
                          }}
                          name="user"
                          styles={selectStylesPop}
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          className="form-control"
                        ></Select>
                        <div className="text-danger">
                          {this.state.errors.user}
                        </div>
                      </div>

                      <div className="credit">
                        <label>{i18n.t("amount")}</label>
                        <input
                          type="text"
                          name="credit"
                          value={this.state.add.credit}
                          onChange={this.handleAddChange}
                          autoComplete="credit"
                          className="form-control"
                        />

                        <div className="text-danger">
                          {this.state.errors.credit}
                        </div>
                      </div>

                      <div className="reference">
                        <label>{i18n.t("reference")}</label>
                        <input
                          type="text"
                          name="reference"
                          value={this.state.add.reference}
                          onChange={this.handleAddChange}
                          className="form-control"
                        />
                        <div className="text-danger">
                          {this.state.errors.reference}
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={this.handleSubmit}
                      >
                        {i18n.t("submit")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.update_popup}
          onHide={() => this.setState({ update_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              View/Edit User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditUser
              data={this.state.data}
              triggerReload={this.triggerReload}
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
