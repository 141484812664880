import React from "react";
import { SideBar } from "./sidebar";
import {
  getEnrolledUsers,
  activateUserEnroll,
  deActivateUserEnroll,
  activateAllUserEnroll,
  deleteEnrolledUser,
  approveOrRejectEnrolls,
  enrollBulkDelete,
} from "../../models/auctions";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import { getAllGroups } from "../../models/groups";
import { HomeBanner } from "../home/homeBanner";
import { apiUrl } from "../../constants/global";
import { getLoggedUser } from "../../state/user";
import { generateMzadcomID } from "../../state/utils";
import { ListTable } from "../helpers/skeletons/ListTable";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const user = getLoggedUser();
export class EnrolledUsers extends React.Component {
  constructor() {
    super();
    this.state = {
      bulk_updating: false,
      row_updating: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
        type: "active",
      },
      query: "",
      auctions: {},
      is_loading: false,
      groups: {},
      isChecked: false,
      isAllChecked: false,
      selected_ids: [],
      users_filter: [],
      columns: [
        // {
        //   cell: (row) => {
        //     return (
        //       <input
        //         type="checkbox"
        //         value={row.id}
        //         checked={row.isChecked}
        //         onChange={() => this.handleCheckboxChange(row.id)}
        //         disabled={row.status === "A"}
        //       />
        //     );
        //   },
        // },
        {
          name: "ID",
          sortable: true,
          width: "100px",
          cell: (row) => row.id,
        },
        {
          name: "Enroll ID",
          sortable: true,
          width: "100px",
          cell: (row) => row.enroll_number,
        },
        {
          name: "Auction ID",
          sortable: true,
          width: "100px",
          cell: (row) => row.auction_id,
        },
        {
          name: i18n.t("name"),
          sortable: true,
          cell: (row) => row.user.name,
        },
        {
          name: i18n.t("username"),
          sortable: true,
          cell: (row) => row.user.username,
        },
        {
          name: i18n.t("email"),
          cell: (row) => row.user.email,
        },
        {
          name: i18n.t("mobile"),
          wrap: true,
          cell: (row) => row.user.mobile,
        },
        {
          name: i18n.t("auction"),
          wrap: true,
          cell: (row) => row.auction.title,
        },
        {
          name: i18n.t("identity_type"),
          wrap: true,
          cell: (row) => row.identity_type,
        },
        {
          name: i18n.t("Civil Card"),
          cell: (row) =>
            row.file_civil_card ? (
              <a href={row.file_civil_card} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("Signature"),
          cell: (row) =>
            row.file_signature ? (
              <a href={row.file_signature} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("receipt"),
          cell: (row) =>
            row.file_receipt ? (
              <a href={row.file_receipt} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("account_number"),
          wrap: true,
          cell: (row) => row.account_number || "No",
        },
        {
          name: i18n.t("is_company"),
          wrap: true,
          cell: (row) => (row.is_company === 1 ? "Yes" : "No"),
        },
        {
          name: i18n.t("cr_number"),
          wrap: true,
          cell: (row) => (row.is_company === 1 ? row.cr_number : "No"),
        },
        {
          name: i18n.t("Bank Account"),
          cell: (row) =>
            row.file_ba ? (
              <a href={row.file_ba} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("Bank Guarantee"),
          cell: (row) =>
            row.file_bg ? (
              <a href={row.file_bg} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("CR Certificate"),
          cell: (row) =>
            row.file_cr ? (
              <a href={row.file_cr} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("payment type"),
          wrap: true,
          cell: (row) => {
            return row.pay_type;
            // if (row.auction.payment_type === "Both") {
            //   return "Online & Offline";
            // }
            // return row.auction.payment_type;
          },
        },
        {
          name: i18n.t("payment status"),
          wrap: true,
          cell: (row) =>
            row.payment != null && row.payment.status === "P" ? (
              <span className="text-success">Paid</span>
            ) : (
              <span className="text-danger"></span>
            ),
        },
        {
          name: i18n.t("status"),
          cell: (row) =>
            row.status === "A" ? (
              <span className="text-success">Approved</span>
            ) : (
              <span className="text-danger">Pending</span>
            ),
        },
        {
          name: i18n.t("Action"),
          button: true,
          cell: (row) => {
            return (
              <div className="d-flex">
                <button
                  className={
                    row.status === "A"
                      ? "btn btn-info btn-sm"
                      : "btn btn-success btn-sm"
                  }
                  onClick={(e) => {
                    if (row.status === "A") {
                      row.status = "N";
                      this.deActivate(row.id);
                    } else {
                      row.status = "A";
                      this.activate(row.id);
                    }
                  }}
                >
                  {this.state.row_updating && <Spinner animation="animation" />}
                  {!this.state.row_updating && (
                    <span>
                      {row.status === "A"
                        ? i18n.t("Disable")
                        : i18n.t("Enable")}
                    </span>
                  )}
                </button>
              </div>
            );
          },
        },
        {
          name: i18n.t("delete"),
          button: true,
          cell: (row) => {
            return (
              <div className="d-flex">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => {
                    if (row.status === "A") {
                      return;
                    } else {
                      row.status = "A";
                      this.deleteEnroll(row.id);
                    }
                  }}
                  disabled={row.status === "A"}
                >
                  {this.state.row_updating && <Spinner animation="animation" />}
                  {!this.state.row_updating && (
                    <span>
                      <i className="fa fa-times" />
                    </span>
                  )}
                  {/* {!this.state.row_updating && <span>{i18n.t("delete")}</span>} */}
                </button>
              </div>
            );
          },
        },
      ],
    };
    this.loadAllGroups = this.loadAllGroups.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.activateAllUserEnroll = this.activateAllUserEnroll.bind(this);
    this.activate = this.activate.bind(this);
    this.deActivate = this.deActivate.bind(this);
    this.deleteEnroll = this.deleteEnroll.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllGroups();
    this.loadAllEnrolledUsers(qstring);
  }

  async loadAllGroups() {
    const response = await getAllGroups("");
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllEnrolledUsers(q) {
    this.setState({ is_loading: true });
    if (this.state.input.group) {
      q = q + "&group=" + this.state.input.group;
    }
    if (this.state.input.user_name) {
      q = q + "&uname=" + this.state.input.user_name;
    }
    this.setState({ query: q });
    const response = await getEnrolledUsers(q);
    let users = response.data;
    users = users.map((item) => ({
      ...item,
      isChecked: false,
    }));
    let meta = response.meta;
    if (users) {
      this.setState({ is_loading: false });
      this.setState({ auctions: users });
      this.setState({ totalRows: meta.total });
      this.setState({ users_filter: meta.users });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });

    let query = this.state.query;
    let new_q = this.removeUrlParameter(query, "group", "uname");
    console.log(new_q);
    this.setState({ query: new_q });
  }

  removeUrlParameter(url, paramKey1, paramKey2) {
    url = url
      .replace(new RegExp("[?&]" + paramKey1 + "=[^&#]*(#.*)?$"), "$1")
      .replace(new RegExp("([?&])" + paramKey1 + "=[^&]*&"), "$1");
    url = url
      .replace(new RegExp("[?&]" + paramKey2 + "=[^&#]*(#.*)?$"), "$1")
      .replace(new RegExp("([?&])" + paramKey2 + "=[^&]*&"), "$1");
    return url;
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllEnrolledUsers(qstring);
  };

  activateAllUserEnroll() {
    swal("Are you sure.? Do you want to continue.?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then((value) => {
      if (value === "yes") {
        const response = activateAllUserEnroll({
          group: this.state.input.group,
        });
        swal(
          i18n.t("success"),
          "All enrollments activated for this user.",
          "success",
          {
            buttons: false,
            timer: 2000,
          }
        );
        this.loadAllEnrolledUsers(this.state.query);
      }
      return false;
    });
  }

  async activate(id) {
    this.setState({ row_updating: true });
    const response = await activateUserEnroll({ enroll_id: id });
    swal(i18n.t("success"), "User activated", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.setState({ row_updating: false });
        this.loadAllEnrolledUsers(this.state.query);
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  async deActivate(id) {
    this.setState({ row_updating: true });
    const response = await deActivateUserEnroll({ enroll_id: id });
    swal(i18n.t("success"), "User disabled", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.setState({ row_updating: false });
        this.loadAllEnrolledUsers(this.state.query);
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  async deleteEnroll(id) {
    swal("Are you sure.? Do you want to continue.?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then((value) => {
      if (value === "yes") {
        this.setState({ row_updating: true });
        const response = deleteEnrolledUser(id);
        if (response.success) {
          swal(i18n.t("success"), "User enrollment deleted.", "success", {
            buttons: false,
            timer: 2000,
          });
          this.loadAllEnrolledUsers(this.state.query);
          this.setState({ row_updating: false });
        } else {
          this.setState({ row_updating: false });
        }
      }
      return false;
    });
  }

  async deleteSelected() {
    let { selected_ids } = this.state;
    if (!selected_ids.length) {
      swal("Error", "Please select any id", "error");
      return;
    }
    swal("Are you sure.? Do you want to delete selected enrollments.?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "no") {
        return;
      }
      this.setState({ row_updating: true });
      let payload = {
        ids: selected_ids,
      };
      const response = await enrollBulkDelete(payload);
      if (response.success) {
        swal(i18n.t("success"), "Selected enrollments deleted.", "success", {
          buttons: false,
          timer: 2000,
        });
        this.loadAllEnrolledUsers(this.state.query);
        this.setState({ row_updating: false });
      } else {
        this.setState({ row_updating: false });
      }

      return false;
    });
  }

  async approveRejectSelected(is_approve) {
    let { selected_ids } = this.state;
    if (!selected_ids.length) {
      swal("Error", "Please select any id", "error");
      return;
    }
    let msg = is_approve
      ? "Are you sure.? Do you want to approve selected enrollments.?"
      : "Are you sure.? Do you want to reject selected enrollments.?";
    let msg_2 = is_approve
      ? "Selected enrollments approved."
      : "Selected enrollments rejected.";
    swal(msg, {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "no") {
        return;
      }
      this.setState({ row_updating: true, bulk_updating: true });
      let payload = {
        ids: selected_ids,
        is_approve: is_approve,
      };
      const response = await approveOrRejectEnrolls(payload);
      console.log(response);
      if (response.success) {
        swal(i18n.t("success"), msg_2, "success", {
          buttons: false,
          timer: 2000,
        });
        this.loadAllEnrolledUsers(this.state.query);
        this.setState({ row_updating: false, bulk_updating: false });
      } else {
        this.setState({ row_updating: false, bulk_updating: false });
      }
      return false;
    });
  }

  render() {
    let { groups, users_filter, auctions, input, selected_ids } = this.state;
    let queryString = new URLSearchParams(input).toString();
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      {i18n.t("enrolled_users")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {/* <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("type")}</label>
                          <select
                            className="form-control"
                            data-placeholder={i18n.t("type")}
                            name="type"
                            value={this.state.input.type}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("type")}--</option>
                            <option value="active">{i18n.t("active")}</option>
                            <option value="upcoming">
                              {i18n.t("upcoming")}
                            </option>
                            <option value="previous">
                              {i18n.t("previous")}
                            </option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction_group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("username")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select username"
                            name="user_name"
                            value={this.state.input.user_name}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("username")}--</option>
                            {users_filter.length > 0 &&
                              users_filter.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.username} (
                                    {generateMzadcomID(item.id, item.role)}))
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            Name/Username/email/phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name, Username, email or phone"
                            name="user_name"
                            value={this.state.input.user_name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("pay_type")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder={i18n.t("pay_type")}
                            name="pay_type"
                            value={this.state.input.pay_type}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("pay_type")}--</option>
                            <option value="online">{i18n.t("online")}</option>
                            <option value="offline">{i18n.t("offline")}</option>
                            <option value="wallet">{i18n.t("wallet")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("status")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder={i18n.t("pay_type")}
                            name="status"
                            value={this.state.input.status}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("status")}--</option>
                            <option value="A">{i18n.t("approved")}</option>
                            <option value="N">{i18n.t("not_approved")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-1 col-md-1">
                        <div className="form-group mt-4 mb-0">
                          <button
                            type="button"
                            className="btn btn-primary rounded-pill mt-2"
                            onClick={(e) => {
                              this.setState({ selected_ids: [] });
                              let qstring = new URLSearchParams(
                                input
                              ).toString();
                              this.loadAllEnrolledUsers(qstring);
                            }}
                          >
                            <i className="fa fa-search" />
                            &nbsp;
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>

                    {!this.state.is_loading && auctions.length === 0 && (
                      <div className="text-danger text-center p-3 border border-danger">
                        {i18n.t("no_data_found")}
                      </div>
                    )}

                    <div className="table-responsive ">
                      {this.state.is_loading && <ListTable />}
                      {!this.state.is_loading && auctions.length > 0 && (
                        <div className="table-wrapper">
                          <div className="d-flex justify-content-between my-1">
                            {(user.role === "A" || user.role === "F") && (
                              <div>
                                {user.role === "A" && (
                                  <button
                                    className="btn btn-sm btn-outline-danger rounded-0"
                                    disabled={selected_ids.length < 1}
                                    onClick={() => {
                                      this.deleteSelected();
                                    }}
                                  >
                                    <i className="fa fa-trash" />
                                    &nbsp;{i18n.t("delete")}
                                  </button>
                                )}
                                <button
                                  className="btn btn-sm btn-outline-success rounded-0"
                                  disabled={selected_ids.length < 1}
                                  onClick={() => {
                                    this.approveRejectSelected(true);
                                  }}
                                >
                                  {this.state.bulk_updating ? (
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    <i className="fa fa-check" />
                                  )}
                                  &nbsp;{i18n.t("approve")}
                                </button>
                                <button
                                  className="btn btn-sm btn-outline-warning rounded-0"
                                  disabled={selected_ids.length < 1}
                                  onClick={() => {
                                    this.approveRejectSelected(false);
                                  }}
                                >
                                  <i className="fa fa-ban" />
                                  &nbsp;{i18n.t("reject")}
                                </button>
                              </div>
                            )}

                            {this.state.input.group && auctions.length > 0 && (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-info rounded-0"
                                onClick={this.activateAllUserEnroll}
                              >
                                Activate all enrolls in this group
                              </button>
                            )}
                            <a
                              className="btn btn-outline-danger btn-lg rounded-0"
                              href={
                                apiUrl +
                                `enrolled_users/excel?l_user=${user.id}&${queryString}`
                              }
                              target="_new"
                            >
                              <span className="text-danger">
                                <i className="fa fa-file-excel-o fa-2x" />
                              </span>
                            </a>
                          </div>
                          <DataTable
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={auctions}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={this.state.input.limit}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            onChangeRowsPerPage={(size) => {
                              this.setState({ rows_per_page: 15 });
                              let { input } = this.state;
                              input.limit = size;
                              this.setState({ input });
                              this.handlePageChange(1);
                            }}
                            paginationComponentOptions={{
                              rowsPerPageText: "Enrolls per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                            selectableRows={
                              user.role === "A" || user.role === "F"
                            }
                            onSelectedRowsChange={(e) => {
                              let { selectedRows } = e;
                              const selectedIds = selectedRows.map(
                                (row) => row.id
                              );
                              this.setState({ selected_ids: selectedIds });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
