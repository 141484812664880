import React from "react";
import { getLoggedUser } from "../../../state/user";
import i18n from "../../../i18n";
import swal from "sweetalert";
const user = getLoggedUser();
const statuses = {
  A: "Waiting for PM approval",
  PA: "Refund Approved",
  GA: "Waiting for CEO approval",
  CA: "Refund Approved by CEO",
  RR: "Refund Rejected",
  P: "Paid",
  N: "Not Paid",
  R: "Refunded",
};
const statusColors = {
  A: "danger",
  PA: "success",
  GA: "info",
  CA: "success",
  RR: "danger",
  P: "success",
  N: "danger",
  R: "warning",
};
const types = {
  enroll: i18n.t("enroll"),
  sitevisit: i18n.t("site_visit"),
  online: i18n.t("online"),
  offline: i18n.t("offline"),
  wallet: i18n.t("wallet"),
};
export class RefundRow extends React.Component {
  constructor() {
    super();
    this.state = {
      amount: 0,
      edit_amount: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let { item } = this.props; //pay_type, type
    let amount = 0;
    // if (type === "enroll" && item.group && item.group.amount) {
    //   amount = item.refund_amount;
    //   // if (pay_type === "wallet") {
    //   //   amount = item.group.amount;
    //   // }
    // }
    // if (type === "site_visit" && item.amount) {
    //   amount = item.refund_amount;
    // }
    amount = item.refund_amount;
    this.setState({ amount, edit_amount: amount });
  }

  handleChange(event) {
    let { edit_amount } = this.state;
    edit_amount = event.target.value;
    this.setState({ edit_amount });
  }

  render() {
    const { index, item, type } = this.props;
    let slNo = index + 1;
    let auction = "";
    if (item && item.auction) {
      auction = item.auction.title;
    }
    let selected_user = {};
    if (type === "enroll") {
      selected_user = item.user;
    }
    if (type === "site_visit") {
      selected_user = item.bidder_info;
    }
    let { amount, edit_amount } = this.state;
    let btn_class = "p-0 m-0 py-1 px-2 rounded-0 border-1 text-center";
    let amt_msg = "";
    let button_disable = false;
    if (edit_amount > amount) {
      btn_class =
        "p-0 m-0 py-1 px-2 rounded-0 border-1 text-center border-danger";
      amt_msg = `Amount should be less than ${amount}`;
      button_disable = true;
    }
    let check_disabled =
      user.role === "A" || item.rank == 1 || item.rank == 2 ? true : false;
    return (
      <>
        <tr key={index}>
          {!this.props.is_completed && (
            <th scope="row">
              {!check_disabled && (
                <input
                  type="checkbox"
                  value={item.id}
                  checked={item.isChecked}
                  onChange={() => this.props.checkboxChange(item.id)}
                  disabled={check_disabled}
                />
              )}
              {check_disabled && (
                <input
                  type="checkbox"
                  value={null}
                  checked={false}
                  disabled={true}
                />
              )}
            </th>
          )}
          <td>{slNo}</td>
          <td>{item.id}</td>
          <td>
            {type === "enroll" && types[item.pay_type]}
            {type === "site_visit" && types[item.payment_type]}
          </td>
          <td>{selected_user.id}</td>
          <td>
            <button
              className="btn rounded-circle btn-outline-info btn-sm px-2 py-1"
              onClick={() => {
                this.props.showInfoPopup(selected_user);
              }}
            >
              <i className="fa fa-info fa-1x" />
            </button>
          </td>
          <td>{selected_user.name}</td>
          <td>
            {type === "enroll" && item.group && item.group.group_name}
            {type === "site_visit" &&
              item.group_info &&
              item.group_info.group_name}
          </td>
          <td>{auction}</td>
          <td>
            {!this.state.is_edit_row && (
              <div className="d-flex align-items-center justify-content-between">
                <span>{amount}</span>
                {/* <button
                  className="btn btn-sm btn-outline-warning py-0 px-2"
                  onClick={() => {
                    this.setState({ is_edit_row: !this.state.is_edit_row });
                  }}
                >
                  <i className="fa fa-pencil" />
                </button> */}
              </div>
            )}

            {this.state.is_edit_row && (
              <>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    name="edit_amount"
                    value={this.state.edit_amount}
                    style={{ width: "85px" }}
                    className={btn_class}
                    onChange={this.handleChange}
                  />
                  <button
                    className="p-0 m-0 btn btn-success py-1 px-2 rounded-0"
                    onClick={() => {}}
                    disabled={button_disable}
                  >
                    <i className="fa fa-save" />
                  </button>
                  <button
                    className="p-0 m-0 btn btn-outline-danger py-1 px-2 rounded-0"
                    onClick={() => {
                      this.setState({ is_edit_row: false });
                    }}
                  >
                    <i className="fa fa-times-circle" />
                  </button>
                </div>
                <div className="text-danger">
                  <small>{amt_msg}</small>
                </div>
              </>
            )}
          </td>
          <td>{item.created_at}</td>
          <td>{item.rank}</td>
          <td className={`text-${statusColors[item.refund_status]}`}>
            {statuses[item.refund_status]}
          </td>
        </tr>
      </>
    );
  }
}
