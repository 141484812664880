import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export class SkeletonAuctionDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let baseColor = "#1f97c5";
    return (
      <React.Fragment>
        <div className="container">
          <div className="col-xl-6 col-lg-12 col-md-12 d-block mx-auto">
            <Skeleton
              height={50}
              baseColor={baseColor}
              className="card"
              style={{ borderRadius: "30px" }}
            />
          </div>
          <div className="row">
            <div className="col-lg-8">
              <Skeleton
                height={550}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
              <Skeleton
                height={50}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "5px" }}
              />
              <Skeleton
                height={50}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "5px" }}
              />
              <Skeleton
                height={50}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "5px" }}
              />
              <Skeleton
                height={50}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "5px" }}
              />
              <Skeleton
                height={50}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "5px" }}
              />
              <Skeleton
                height={50}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "5px" }}
              />
            </div>
            <div className="col-lg-4">
              <Skeleton
                height={250}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
              <Skeleton
                height={550}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
              <Skeleton
                height={150}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
